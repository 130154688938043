// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';



main{
    min-height: calc( 100vh - 16rem);
}

nav.navbar{
    background: $red;
}

h1,h2,h3,h4,h5,h6{
    font-weight:700;
}

h1{
    font-size: 2rem;
}

h2{
    font-size: 1.6rem;
    border-bottom:1px solid #ced4da;
    padding-bottom: .5rem;
    margin-bottom: 1.25rem;
}

h3{
    font-size: 1.3rem;
}

h4{
    font-size: 1.2rem;
}

h5{
    font-size: 1.1rem;
}

h6{
    font-size: 1.05rem;
}

label, dt{
    margin-bottom: 0;
    font-weight:700;
}

.card-header{
        background: rgba(0,0,0,.01);

}

#file {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
}

#file+label {
    color: #fff;
    background-color: #045caa;
    border-color: #045caa;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    line-height: 1.6;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#file:focus+label,
#file+label:hover {
    background-color: #f15d22;
}

#file:focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.autocomplete-input {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    padding-left: 3rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.toggle {
	cursor: pointer;
	display: inline-block;
}
.toggle-switch {
	display: inline-block;
	background: #ccc;
	border-radius: 16px;
	width: 48px;
	height: 24px;
	position: relative;
	vertical-align: middle;
	transition: background 0.25s;
	&:before,
	&:after {
		content: "";
	}
	&:before {
		display: block;
		background: linear-gradient(to bottom, #fff 0%,#eee 100%);
		border-radius: 50%;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.25);
		width: 16px;
		height: 16px;
		position: absolute;
		top: 4px;
		left: 4px;
		transition: left 0.25s;
	}
	.toggle:hover &:before {
		background: linear-gradient(to bottom, #fff 0%,#fff 100%);
		box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
	}
	.toggle-checkbox:checked + & {
		background: $green;
		&:before {
			left: 28px;
		}
	}
}
.toggle-checkbox {
	position: absolute;
	visibility: hidden;
}
.toggle-label {
	margin-left: 5px;
	position: relative;
	top: 2px;
}

.required{
    color:$red
}

dt{
    margin-bottom: 0;
    line-height: 1;
    font-size: 1.1rem;
}

dd{
    padding-top: 0;
    padding-bottom: .375rem;
    margin-bottom: 1rem;
}

.btn-border{
    border: 1px solid rgba(0,0,0,.05)
}

.nav-link{
    padding: .25rem;
}

a, a:hover, .nav-link:hover, .nav-link:focus{
    text-decoration: underline;
}
