// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.5;



// Colors
$blue: #0053aa;
$indigo: #2246fa;
$purple: #4d2a81;
$pink: #f66d9b;
$red: #b31b1b;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #006A49;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$main-margin: 100px;

$border-radius:               0;
$border-radius-sm:            0;
$border-radius-lg:            0;
